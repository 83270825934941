// composables/useCurrency.js
import { useCookie } from '#app'; // Ensure you import useCookie appropriately
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { defaultNumberLocales } from '~/components/utils';
import { numberFormats } from '~/i18n/number-format';

export function useCurrency() {
  const { locale } = useI18n();

  const preferredNumberLocaleCookie = useCookie('preferredNumberLocale');
  const preferredNumberLocale = computed(() => preferredNumberLocaleCookie.value || locale.value);

  const currentCurrency = computed(() => {
    const numberLocale = preferredNumberLocale.value;

    //Find the number format for the current locale or where the part after - is the locale value
    let numberFormat = numberFormats[numberLocale];

    if (!numberFormat) {
      numberFormat = numberFormats[defaultNumberLocales[numberLocale] || defaultNumberLocales['en']];
    }

    const selectedFormat = numberFormat || numberFormats['en-US'];
    if (!selectedFormat || !selectedFormat.currency || !selectedFormat.currency.currency) {
      return null;
    }

    return selectedFormat.currency.currency;
  });

  return {
    currentCurrency,
  };
}
